import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import SelectBox from '../../components/SelectBox'
import usePaymentMethod from '../../hooks/usePaymentMethod'
import TextBox from '../../components/Inputs/TextBox'
import useWithdraw from '../../hooks/useWithdraw'
import { getUserProfile } from '../../service/authService'

const img = {
  width: 150,
  height: 150,
  border: '1px solid #67C9FF',
  borderRadius: '10px',
}

function Deposti() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm()

  const inputFile = useRef(null)
  const [actionLoading, setActionLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [amount, setAmount] = useState(null)
  const [tokenType,setTokenType]=useState(1);
  const [mounted, setMounted] = useState(false)

  const { state: paymentState, fetchPaymentMethod } = usePaymentMethod()

  const { storeWithdraw } = useWithdraw()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPaymentMethod()
    }
  }, [mounted, fetchPaymentMethod])

  const submitData = async (values) => {
    if (image) {
      setActionLoading(true)
      const user = await getUserProfile()
      if (tokenType===1 && user.holdToken<Number(values.amount)){
          NotificationManager.warning("You don't have enough total!")
          setActionLoading(false)
        return ;
      }

      if (tokenType===2 && user.myToken<Number(values.amount)){
        NotificationManager.warning("You don't have enough total!")
        setActionLoading(false)
        return ;
      }

      const formData = new FormData()
      formData.append('amount', values.amount)
      formData.append('note', values.note)
      formData.append('address', values.address)
      formData.append("tokenType",tokenType);
      formData.append('image', image)
      await storeWithdraw(formData)
      reset()
      setImage(null)
      setActionLoading(false)
    } else {
      NotificationManager.warning('Please upload image!')
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)} encType="multipart/form-data">
        <Card
          style={{
            border: '3px solid #2A2B57',
            marginTop: '8px',
            borderRadius: '15px',
            padding: '10px',
            background:
              'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
          }}
        >
          <CardHeader>
            <h4 className="text-white">Make Withdraw Request</h4>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <label className="mb-2 text-white">Token Type</label>
              <SelectBox
               defaultValue={1}
                control={control}
                className="form-control"
                name="tokenType"
                rules={{ required: true }}
                label="Token Type"
                options={[
                  { value: 1, label: 'Hold Token' },
                  { value: 2, label: 'My Token' },
                ]}
                value={tokenType}
                onValueChange={(value) => {
                  setTokenType(value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Amount</label>
              <SelectBox
                control={control}
                className="form-control"
                name="amount"
                rules={{ required: true }}
                label="Amount"
                options={[
                  { value: '10', label: '10' },
                  { value: '100', label: '100' },
                  { value: '300', label: '300' },
                  { value: '600', label: '600' },
                  { value: '1000', label: '1000' },
                  { value: '3000', label: '3000' },
                ]}
                value={amount}
                onValueChange={(value) => {
                  setAmount(value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Address</label>
              <TextBox
                type="text"
                registerProps={register('address', {
                  required: 'Address is required',
                })}
                errors={errors.address}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Note</label>
              <TextBox
                type="text"
                registerProps={register('note', {
                  required: 'Note is required',
                })}
                errors={errors.note}
              />
            </FormGroup>
            <FormGroup>

              <div className="mt-5">
              <label className="mb-2 text-white">10% Deposit Payment</label>
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    style={{ width: 300, height: 300 }}
                    onClick={() => {
                      inputFile.current.click()
                    }}
                    alt=""
                  />
                ) : (
                  <div
                    onClick={() => {
                      inputFile.current.click()
                    }}
                    style={{
                      width: 300,
                      height: 300,
                      background: 'rgba(15, 16, 40, 0.4)',
                      color: 'white',
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h4>Upload+</h4>
                  </div>
                )}
              </div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setImage(event.target.files[0])
                  }
                }}
                accept="image/*"
              />
            </FormGroup>
            <FormGroup className="mt-2">
              <div className="d-flex justify-content-start py-4">
                <label className="mb-2 text-white">Payment Method</label>
              </div>
              {paymentState.paymentMethods.map((payment, index) => (
                <div
                  className="d-flex justify-content-between mb-3"
                  key={index}
                  style={{ color: '#67C9FF' }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <h6>QR Code</h6>
                    <img style={img} src={payment.QRCode} alt="QR Code" />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Email ({payment.email})</h6>
                    <img
                      style={img}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Email"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Address ({payment.address})</h6>
                    <img
                      style={img}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Address"
                    />
                  </div>
                </div>
              ))}
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-end">
            <Button
              size="lg"
              color="success"
              disabled={actionLoading}
              className="button"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default Deposti
